export const MOTION_SPRING_LONG = {
  damping: 1,
  mass: 10,
  stiffness: 50,
  velocity: 10,
} as const;

export const MOTION_SPRING_SHORT = {
  ...MOTION_SPRING_LONG,
  mass: MOTION_SPRING_LONG.mass * 0.66,
} as const;

export const MOTION_SPRING_VERY_SHORT = {
  ...MOTION_SPRING_LONG,
  mass: MOTION_SPRING_LONG.mass * 0.33,
} as const;
