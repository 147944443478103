export const CONVERSION_TYPES = [
  "console_signup",
  "contact_sales",
  "content_report",
  "content_video",
  "content_webinar",
  "event_registration",
  "newsletter_signup",
  "partnerships",
  "product_early_access",

  /**
   * @deprecated - here only for debugging
   * This conversion types have been substituted with the new ones above,
   * they are still here to allow to spot if they are still
   * erroneously being triggered somewhere due to Storyblok settings
   * (see Google Analytics data to check).
   */
  "primary_conversion",
  "secondary_conversion",
] as const;

export type ConversionEvent = (typeof CONVERSION_TYPES)[number];
