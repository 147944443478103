/* eslint-disable sonarjs/no-duplicate-string */
import type { EnabledLanguageIsoCode } from "./ENABLED_LANGUAGES.config";

export const HUBSPOT_FORMS_MAP: Partial<
  Record<EnabledLanguageIsoCode, string>
> = {
  "de-de": "4d19de4e-37ad-4b0c-b191-6ecb3de0f22c",
  "en-gb": "717c46dc-3275-4157-a4e0-735ec420ec73",
  "en-ie": "717c46dc-3275-4157-a4e0-735ec420ec73",
  "en-se": "717c46dc-3275-4157-a4e0-735ec420ec73",
  "es-es": "9ba7ad7d-e389-4790-a1dc-50099b30d54e",
  "fr-fr": "b0578107-9233-4578-b43e-8ddb429c38cd",
  "it-it": "a7825c05-8cdf-4d51-9a08-eb4f563b2cff",
};
